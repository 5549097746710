import { MAINTAIN_PERMISSION, LINE_PERFORMANCE_TARGETS } from "@kraftheinz/common";
import { n as normalizeComponent } from "./vueComponentNormalizer.js";
var render$1 = function() {
  var _vm = this;
  var _h = _vm.$createElement;
  var _c = _vm._self._c || _h;
  return _c("list-composer", { attrs: { "has-advanced-search": false, "title": "Targets", "search-by": "FacilityName,AreaName,LineCode,OEETarget,BoardTarget", "ph-search-by": "Search by keywords", "scroll": { x: 1200 }, "fetch-on-first-mount": false }, scopedSlots: _vm._u([{ key: "action", fn: function(ref) {
    var edit = ref.edit;
    var record = ref.record;
    var toggleActive = ref.toggleActive;
    return [_vm.can(_vm.permissions.maintain) ? _c("a-tooltip", { scopedSlots: _vm._u([{ key: "title", fn: function() {
      return [_vm._v("Edit")];
    }, proxy: true }], null, true) }, [_c("a-button", { staticClass: "mr-1", attrs: { "icon": "edit", "size": "small", "type": "link", "disabled": record.isInactive }, on: { "click": function($event) {
      return edit(record);
    } } })], 1) : _vm._e(), _vm.can(_vm.permissions.maintain) ? _c("a-tooltip", { scopedSlots: _vm._u([{ key: "title", fn: function() {
      return [_vm._v(_vm._s(record.isInactive ? "Activate" : "Deactivate"))];
    }, proxy: true }], null, true) }, [_c("a-button", { staticClass: "list__action-toggle-active", attrs: { "icon": record.isInactive ? "eye-invisible" : "eye", "size": "small", "type": "link" }, on: { "click": function($event) {
      return toggleActive(record);
    } } })], 1) : _vm._e()];
  } }]) }, [_c("text-field", { key: "FacilityName", attrs: { "data-index": "facilityName", "title": "Facility", "sorter": true } }), _c("text-field", { key: "AreaName", attrs: { "data-index": "areaName", "title": "Department", "sorter": true } }), _c("text-field", { key: "ProductionLineCode", attrs: { "data-index": "productionLineCode", "title": "Line", "sorter": true } }), _c("text-field", { key: "OeeTarget", attrs: { "data-index": "oeeTarget", "title": "OEE Target", "sorter": true } }), _c("text-field", { key: "AvailabilityTarget", attrs: { "data-index": "availabilityTarget", "title": "Availability Target", "sorter": true } }), _c("text-field", { key: "PerformanceTarget", attrs: { "data-index": "performanceTarget", "title": "Performance Target", "sorter": true } }), _c("text-field", { key: "QualityTarget", attrs: { "data-index": "qualityTarget", "title": "Quality Target", "sorter": true } })], 1);
};
var staticRenderFns$1 = [];
var ListTargets_vue_vue_type_style_index_0_scoped_true_lang = /* @__PURE__ */ (() => ".custom-filter[data-v-43e38514] .select-input{width:160px}\n")();
const __vue2_script$1 = {
  name: "ListTargets",
  inject: ["apiUrl", "crud", "resourceName", "facility", "can", "getArea"],
  data() {
    return {
      permissions: { maintain: MAINTAIN_PERMISSION }
    };
  },
  computed: {
    area() {
      return this.getArea();
    }
  },
  watch: {
    area(newVal) {
      this.crud.setFilter("AreaId", { operator: "Eq", value: newVal });
      this.fetchList();
    }
  },
  async created() {
    this.crud.setFilter("AreaId", { operator: "Eq", value: this.area });
    this.fetchList();
  },
  methods: {
    fetchList() {
      const { count, pageSize } = this.crud.getPagination();
      this.crud.setPagination({ count, page: 1, pageSize });
      this.crud.fetchList();
    },
    showErrorMsg(err) {
      this.$notification["error"]({
        message: err.response.data.message || "Errors"
      });
    }
  }
};
const __cssModules$1 = {};
var __component__$1 = /* @__PURE__ */ normalizeComponent(__vue2_script$1, render$1, staticRenderFns$1, false, __vue2_injectStyles$1, "43e38514", null, null);
function __vue2_injectStyles$1(context) {
  for (let o in __cssModules$1) {
    this[o] = __cssModules$1[o];
  }
}
var ListTargets = /* @__PURE__ */ function() {
  return __component__$1.exports;
}();
var render = function() {
  var _vm = this;
  var _h = _vm.$createElement;
  var _c = _vm._self._c || _h;
  return _c("div", { staticClass: "list-contracts" }, [_c("bread-crumb", { attrs: { "items": _vm.itemsMenu } }), _c("resource", { attrs: { "name": "line-performance.targets", "api-url": _vm.apiUrl, "create-route": "/line-performance/targets/create", "edit-route": "/line-performance/targets/:id", "page": _vm.page } }, [_c("list-targets")], 1), _c("router-view")], 1);
};
var staticRenderFns = [];
const apiUrl = "#{VUE_APP_API_URL}#";
const __vue2_script = {
  name: "Targets",
  components: { ListTargets },
  data() {
    return {
      page: LINE_PERFORMANCE_TARGETS,
      apiUrl,
      itemsMenu: [
        {
          key: "masterfiles",
          title: "Master Files",
          path: ""
        },
        {
          key: "targets",
          title: "Targets",
          path: "/line-performance/targets"
        }
      ]
    };
  }
};
const __cssModules = {};
var __component__ = /* @__PURE__ */ normalizeComponent(__vue2_script, render, staticRenderFns, false, __vue2_injectStyles, null, null, null);
function __vue2_injectStyles(context) {
  for (let o in __cssModules) {
    this[o] = __cssModules[o];
  }
}
var index = /* @__PURE__ */ function() {
  return __component__.exports;
}();
export { index as default };
